<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Venues Sales Hit List</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6" v-loading="loading">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <div>
            <div class="col-12 text-right">
              <div>
                <base-input
                  id="search_input"
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search..."
                />
              </div>
            </div>

            <el-table
              :data="queriedData"
              row-key="id"
              id="venues_data_table"
              v-loading="loading"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="Name"
                min-width="220px"
                prop="name"
                sortable
              >
                <template v-slot="{ row }">
                  <div class="media align-items-center">
                    <div class="media-body">
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.name
                      }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Leads Count" prop="leads_count" min-width="250px">
                <template v-slot="{ row }">
                  {{ row.leads_count }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Table, TableColumn } from "element-ui";
export default {
  components: {
    RouteBreadCrumb,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      searchQuery: '',
      loading: true,
    };
  },
  async created() {
    await this.$store.dispatch("reports/getSalesHitList", this.country);
    this.loading = false;
  },
  computed: {
    queriedData() {
      let data =  [...this.$store.state.reports.sales_hit_list];
      if (this.searchQuery) {
        const searchQuery = this.searchQuery;
        data = data.filter(function(item) {
          return item['name'].toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        });
      }
      
      return data;
    },
    ...mapState("country/", {
      country: (state) => state.country || 'AU',
    }),
  },
};
</script>
<style></style>
